@import '../../../style/variables.scss';

:root {
  --qa-amount: 0;
}

.line {
  display: grid;
  grid-template-columns: repeat(var(--qa-amount), 1fr);
  column-gap: 2px;
  width: 100%;
  height: 1.2rem;
  // margin: 1rem;
  margin: 0.5rem 0;
  padding: 3px 0;
  border-radius: 0.5rem;

  @media (min-width: 800px) {
    width: 50%;
  }
}

.progressItem,
.progressItemDone {
  align-content: flex-start;
  // background-color: #0b7285;
  background-color: $colorDarkOrange;
  width: 100%;
}

.progressItem:first-child,
.progressItemDone:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

.progressItem:last-child,
.progressItemDone:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}

.progressItemDone {
  background-color: $colorAccentOrange;
}
