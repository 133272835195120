
.statusIcon {
    height: 3.5rem;
    opacity: 0.2;
    margin-right: -1rem;
}

.IconBigBackgroung {
    width: 40%;
    opacity: 0.15;
    margin-bottom: -1rem;
}

.coverOpen {
    position: relative;
    width: 100%;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 0.5rem 0 0 0.5rem;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #0606067f;
    }

    &:hover {
        &::before {
            background-color: transparent;
        }
    }
}