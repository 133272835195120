.coverFrame {
  width: 100%;
  height: 15rem;
  // width: 14.5rem;
  width: 100%;
  border-radius: 0.5rem 0 0 0.5rem;

  @media (min-width: 800px) {
    width: 100%;
  }
}

.coverOpen {
  position: relative;
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 0.5rem 0 0 0.5rem;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #0606067f;
  }
}
