@import "../style/variables.scss";

.justifyCenter {
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.imgCover {
    margin-top: 2rem;
    width: 100%;
    margin-bottom: 1vh;
}

.mainTitle {
    font-size: 2.4rem;
    color: $colorAccentOrange;
    line-height: 1.1;
}

.subtitle {
    // font-size: 1.8rem;
    // font-weight: 200;
    // color: #adb5bd;

    text-align: center;
    width: 100%;

    color:  #4f5b66;
    font-size: 1.6rem;
    font-weight: 500;

}

.codeCell {
    background-color: #101011;
    height: 4rem;
    width: 3rem;
    border-radius: 0.5rem;
    margin-right: 0.4rem;
}

input.inputCode {
    border-bottom: none;
    position: absolute;
    font-size: 2rem;
    letter-spacing: 2.1rem;
    margin-left: 0.5rem;
    // text-align: center;
}

.lockFrameBorder {
    position: absolute;
    // margin-top: 4.5rem;
    margin-top: 6.5rem;
    height: 11rem;
    width: 86%;
    border: 2px solid #4f5b6671;
    border-radius: 0.5rem;
    z-index: 0;
}