.containerBack {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100vh;

  // justify-content: flex-end;
  padding: 2rem;

  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  //   background-color: #000000ab;

  // background-color: #000000da;
  //   background-color: #1e323fc5;

  overflow-x: hidden;
  overflow-y: scroll;

  @media (min-width: 800px) {
    // max-width: 40rem;
    max-width: 100rem;
    padding: 2rem;
    justify-content: center;
  }
}

.backImage,
.lockedImage,
.fullScreen {
  position: relative;
  object-fit: cover;
  z-index: -30;

  position: absolute;
  top: 0;
  z-index: -10;
  height: 100%;

  @media (min-width: 800px) {
    left: 0;
    width: 48%;
  }
}

.filterContainerImg {
  position: absolute;
  top: 0;
  z-index: -5;
  height: 100%;
  width: 100%;
  background-color: #000000ab;

  @media (min-width: 800px) {
    left: 0;
    width: 48%;
  }
}

.lockedImage {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}

.fullScreen {
  //   background-color: red;
  z-index: 200;
}

.iconLock {
  position: absolute;
  // top: 45%;
  top: 40vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  opacity: 0.5;

  width: 6rem;
  height: 6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../assets/svg/lock.svg');
}
