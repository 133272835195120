@import "../../../style/variables.scss";

.btnFollow,
.btnFollowing  {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    font-size: 1.2rem;
    font-weight: 700;
    color: #212529;
    padding: 0.3rem 3rem;

    // background-color: $colorAccentOrange;
    background-color: transparent;
    color: $colorAccentOrange;
    border: 1px solid $colorBorderLight;
    border-radius: 2rem;

    // new
    width: 100%;

    &:hover {
        background-color: transparent;
        color: $colorAccentOrange;
        transition: all 200ms;
    }
}

.btnFollowing {
    // background-color: transparent;
    // color: $colorAccentOrange;
    background-color: $colorAccentOrange;
    color: #212529;

    &:hover {
        // background-color: $backColorTransparent;
        transition: all 200ms;
    }
}