@import '../../../style/variables.scss';
// $colorAccentOrange;

:root {
  --toastify-icon-color-success: #f59f00 !important;
  --toastify-color-success: #f59f00 !important;
}

.sideBarNav {
  position: absolute;
  top: 2rem;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  z-index: 100;
}

.divDeveloper {
  display: flex;
  flex-direction: column;
  // align-self: flex-end;
  position: absolute;
  top: 2rem;
  align-self: flex-start;
}
