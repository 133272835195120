@import "../../../style/variables.scss";

.headerProfile {
    position: relative;
    cursor: pointer;

    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    // align-items: center;
    padding: 1rem;
    background-color: #181b1e;
    border-radius: 0.5rem;
    &:hover {
        background-color: #212529;
    }
}

.containerDiv {
    width: 100%;
    // new
    // align-self: flex-end;
    align-self: center;
}

.avatar {
    background-color: #adb5bd;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
    object-position: center center;
    flex-shrink: 0;

    // @media (min-width: 700px) {
    //     width: 8rem;
    //     height: 8rem;
    //     margin-right: 1.5rem;
    // }

    // @media only screen 
    // and (device-width : 414px) 
    // // and (device-height : 736px) 
    // // and (device-height : 896px) 
    // and (-webkit-device-pixel-ratio : 2),
    // only screen 
    // and (device-width : 414px) 
    // and (-webkit-device-pixel-ratio : 3) { 
    //     width: 8rem;
    //     height: 8rem;
    //     margin-right: 1.5rem;
    // }

}
.name {
    text-align: left;
    font-weight: 900;
    font-size: 1.4rem;
    line-height: 1.1;
    color: #c6cfd7;
    align-self: flex-end;
    // margin-left: 1.5rem;
    margin-left: 0.5rem;
}

.fontGrey {
    font-size: 1rem;
    color: #adb5bd;
    font-weight: 400;
    padding-top: 0.1rem;
    // color: $colorAccentOrange;
}

.fontGold {
    // margin-top: 0.25rem;
    display: inline-block;
    // width: 20rem;
    font-size: 1.2rem;
    font-weight: 700;
    color: $colorAccentOrange;
    // margin-bottom: 0.5rem;
    align-self: flex-start;
}

.containerNumbers {
    width: 100%;
    display: flex;
    padding-bottom: 1.5rem;
    // border-bottom: 1px solid #adb5bd1f;
}

.numberDiv,
.topicsDiv {
    // width: 100%;
    display: grid;
    // grid-template-columns: 1.4fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 0.5rem;
    // display: flex;
    justify-items: flex-start;
    // modification with numbers 3.3
    align-items: center; 
    font-size: 1rem;
    color: #adb5bd;
    // color: $colorAccentOrange;
    text-align: left;
   
}

// .topicsDiv {
//     align-items: flex-start;
// }

.numberGold,
.numberGrey {
    font-size: 1.8rem;
    font-weight: 300;
    color: $colorAccentOrange;

    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    // margin-right: 0.5rem;
    // margin-left: 1rem;
}

.numberGrey {
    // color: #edf0f2;
    font-size: 2.4rem;
    color: #adb5bd;
}


.socIcon {
    // height: 2.4rem;
    height: 2.2rem;
    border-radius: 2rem;
    margin-right: 0.5rem;
    // border: 1px solid $colorAccentOrange;
}

.socBox {
    display: flex;
    // width: 7rem;
    // border: 1px solid $colorAccentOrange;
    // border-radius: 0.5rem;

    justify-self: flex-start;
    // margin-left: -2.5rem;
    margin-left: 0.5rem;


}

.start {
    // margin-top: 0.5rem;
    // align-self: flex-start;
    height: 100%;
    align-self: center;
    margin-right: 1rem;
}

.marginLeft {
    margin-left: 0.5rem;
    // margin-top: 0.75rem;
}