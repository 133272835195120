.picImg {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 11rem;
    object-fit: cover;
}

.gridGallery {
    position: relative;
    // margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
}

.iconLock {
    width: 50%;
    transform: translate(50%, 4rem);
    z-index: 200;
    opacity: 0.8;
}

.coverBlur {
    position: relative;
    width: 100%;
    height: 100%;
    // backgroundImage from useRef.current
    /* Add the blur effect */
    filter: blur(3px) ;
    -webkit-filter: blur(2px);
    
    // background-color: #171b1e9f;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;

    border-radius: 0.5rem 0 0 0.5rem;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;

        z-index: 10;
        background-color: #171b1ebc;
    }

    // &:hover {
    //     opacity: 0.4;
    // }
}