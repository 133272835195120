.navbarContainer {
    // margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.groupBtn {
    display: flex;
    align-items: flex-end;
}