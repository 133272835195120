@import "../../../style/variables.scss";

.headerProfile {
    display: flex;
    width: 100%;
    
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0rem 0 2rem 0;
}

.containerDiv {
    width: 100%;
    margin-right: 1rem;
    flex-shrink: 1;
    align-self: flex-end;
}

input[type='file'] {
    display: none;
    // color: white;
}
label {
    cursor: pointer;
}
.circleFrame,
.circleFrameError {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 50%;
    background-color: #adb5bd36;
    width: 14.5rem;
    height: 14.5rem;
    margin-right: 2rem;

    &:hover {
        background-color: #adb5bd51;
    }
}
.circleFrameError {
    background-color: #ed09098a;
}

.previewAvatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    
    &::after {
        content: "";
        position: absolute;
        top: 0;
        height: 14.5rem;
        width: 14.5rem;
        background: #292d3051;
        z-index: 200;
    }
}
.previewCoverTransparent {
    position: absolute;
    background: #21252996;
    height: 14.5rem;
    width: 14.5rem;
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 1rem;
}
.iconPhoto {
    width: 4rem;
    height: 4rem;
    margin: 2px;
}

.iconPhotoSmall {
    width: 3rem;
    height: 3rem;
    margin: 2px;
}

.fontBackgroundColor {
    font-size: 1.1rem;
    font-weight: 700;
    color: #212529;
    // color: #21252996;
}


input[type='text'],
input[type='number'] {
    -webkit-appearance: none !important;
    border-radius: none !important;

    background-color: transparent;
    border: none;
    padding: 4px;
    border-bottom: 1px solid #adb5bd36;
    // margin-bottom: 1rem;
    margin-top: 0.5rem;
    color: #adb5bd;
    width: 100%;

}

textarea {
    resize: none;
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 1.4rem;
    width: 100%;
    background-color: transparent;
    border: 1px solid  #adb5bd36;
    border-radius: 4px;
    padding: 0.5rem;
    color: #adb5bd;
}

.nicknameInput,
.nicknameInputError {    
    font-size: 1.4rem;
}
.nicknameInputError {
    border-bottom: 1px solid #ed0909 !important;
    &::placeholder {
        color: #ed0909 !important;
    }
}
.nameInput,
.nameInputError {
    text-align: left;
    font-size: 1.8rem;
    font-weight: 900;
    color: #adb5bd;
}
.nameInputError {
    border-bottom: 1px solid #ed0909 !important;
    &::placeholder {
        color: #ed0909 !important;
    }
}

.containerNumbers {
    width: 100%;
    display: flex;
}
.numberDiv {
    display: grid;
    width: 100%;
    grid-template-columns: 14.5rem 1fr;
    column-gap: 2rem;
    justify-items: flex-start;
    align-items: flex-start; 
    font-size: 1rem;
    color: #adb5bd;
    text-align: left;
}
.selectSocial {
    margin: 0 0 1rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.5rem;
}
.socialIcon {
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    // margin-right: 0.5rem;
}
.fontGold {
    font-size: 1.2rem;
    font-weight: 700;
    color: $colorAccentOrange;
    margin-bottom: 0.5rem;
}

.followersInput {
    text-align: left;
    font-size: 2.2rem;
    font-weight: 500;
    // color: #adb5bd;
    
}
input.followersInput {
    border: 2px solid #adb5bd36;
    border-radius: 0.5rem;
    // width: 10.8rem;
    width: 7rem;
    text-align: right;
    padding: 2px 4px 2px 0;
    margin-bottom: 0.5rem;
    color: $colorAccentOrange;

}
input:focus.followersInput,
input:not(:placeholder-shown).followersInput {
    // border: 2px solid $colorAccentOrange;
    & + .followersK {
        color: $colorAccentOrange;
    }
}
input:focus.followersInput {
    border: 2px solid $colorAccentOrange;
}
.followersK {
    margin-left: 0.5rem;
    text-align: left;
    font-size: 2.2rem;
    font-weight: 500;
    color: #adb5bd7a;
}





.numberGold,
.numberGrey {
    display: flex;
    width: 100%;
    justify-content: center;

    font-size: 3rem;
    font-weight: 200;
    color: $colorAccentOrange;
}

.numberGrey {
    // color: #edf0f2;
    font-size: 3.2rem;
    color: #adb5bd;
}


.socIcon {
    height: 4rem;
    border-radius: 0.5rem;
}

.socBox {
    display: flex;
    width: 14.5rem;
    border: 1px solid $colorAccentOrange;
    border-radius: 0.5rem;
    margin-right: 1rem;
    // margin-top: 0.25rem;
    // margin-bottom: 0.5rem;
}

