.stickerHidden {
    // margin-left: -14rem;
    height: 0rem;

    & img {
        height: 0%;
    }
}

.stickerShow  {
    height: 13rem;
    margin-left: 0rem;
    transition: all 1000ms;

    @media (min-width: 700px) {
        height: 12rem;
    }

    & img {
        height: 100%;
        transition: all 500ms;
    }
}
