@import "../../../style/variables.scss";

.headerProfile {
    // margin from Logo
    margin-top: 6rem;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    // align-items: flex-end;
    align-items: flex-start;
    padding: 1rem 0 2rem 0;
    border-bottom: 1px solid #adb5bd2b;

}
.avatar {
    background-color: #adb5bd;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
    object-position: center center;
    flex-shrink: 0;
}
.name {
    text-align: left;
    margin: 2rem 0 0.5rem 0;
    font-size: 1.8rem;
    font-weight: 900;
    color: #adb5bd;
}
.details,
.details p {
    cursor: pointer;
    font-size: 1.4rem;
    color: #adb5bd;
    margin-bottom: 1rem;

    & span {
        color: $colorAccentOrange;
    }
}

.btnDiv {
    display:flex;
    flex-grow: 1;
    align-self: flex-start;
    justify-content: flex-end;
}
.btnLogout {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #adb5bd;
    cursor: pointer;

    & i {
        & img {
            padding-top: 1px;
            height: 1rem;
        } 
    }

    // &:hover {
    //     color: aquamarine;
    // }

}

