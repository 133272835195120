@import "../../../style/variables";
// $colorAccentOrange;
:root {
    --sizeBtnPlay: 100%;
}

.btn {
    position: relative;
    cursor: pointer;

    // safari my iphone bugs fixed-1:
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%; 
    border: 1px solid #343a40;
    background-color: #1a1e21; 
    
    // width: var(--sizeBtnPlay);
    // padding-top: var(--sizeBtnPlay);
    // background-color: #343a40;

    &:hover,
    &:active {
        // background-color: $colorAccentOrange;
        border: 1px solid $colorAccentOrange;
    }
    
    // &:before {
    //     content: '';
    //     top: 2.5%;
    //     position: absolute;
    //     height: 95%;
    //     width: 95%;
    //     background-color: #1a1e21;
    //     border-radius: 50%;
    // }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%,-50%);
        height: calc(var(--sizeBtnPlay)*2);
        // color: #3bc9db;
        z-index: 10;
    }
}

