@import "../../../style/variables.scss";

.btn,
.btnShare,
.btnSpinner,
.btn35,
.btn35Back,
.btnBack,
.btnReturn {   
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    flex-shrink: 0;
    cursor: pointer;
    background-color: transparent;
    // border: 1px solid #3bc8db89;
    border: 1px solid $colorBorderLight;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;

    margin-bottom: 0.5rem;

    & .icon {
        width: 50%;
    }
}

.btnShare .icon {
    width: 45%;
}

.btn:hover,
.btnShare:hover {
    background-color: $backColorTransparent;
}

.btn35 .icon,
.btn35Back .icon {
    width: 35%;
}

.btn35Back,
.btnBack,
.btnReturn  {
    background-color: #16181b91;
}

.optionLabel {
    margin-top: -0.25rem;
    text-align: center;
    font-size: 1rem;
    color: $colorAccentOrange;
}