@import "../../../style/variables.scss";


.selectOption,
.selectOption option,
.selectOptionBigCoin,
.selectOptionBigCoin option {
    -moz-appearance: none; 
	-webkit-appearance: none; 

    cursor: pointer;
    border: none;
    max-width: 100%;

    color: $colorAccentOrange;
    background-color: transparent;
    // text-align: right;
    font-size: 1.2rem;
    font-weight: 700;


    // align-self: flex-end;
    z-index: 200;
}

.selectOptionBigCoin {
    font-size: 1.8rem;
}
.selectOptionBigCoin option {
    color: $colorAccentOrange;
    text-align: center;
}
.coinBorderBig {
    border: 1px solid $colorAccentOrange;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0.5rem;
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
}

.selectOption select {

}