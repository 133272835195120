@import "../../../style/variables";
// $colorAccentOrange;

.h1Title {
    text-align: center;
    margin: 2rem 0;
    font-size: 2rem;
    font-weight: 900;
    // color: #adb5bd;
    color: $colorAccentOrange;
}

.description {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 0 2rem;
    color: #adb5bd;
}
.hidden {
    opacity: 0;
    // overflow: hidden;
    // margin-top: -15rem;
    transition: all 2000ms;
}
.showText {
    opacity: 1;
    transition: all 2000ms;
}