@import "../../../style/variables.scss";

.iconUnactive,
.iconActive {
    width: 100%;
    padding: 0.75rem;

    @media (min-width: 700px) {
        padding: 0.6rem;
    }
}

.btn {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    // height: 4.25rem;
    width: 4.25rem;

    @media (min-width: 700px) {
        width: 3.5rem;
    }

    &:hover {
        // border-radius: 3rem;
        // background-color: #181b1e;
    }
    
}

.iconActive {

}

