.testCardContainter {
  position: relative;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 4fr 6fr;
  cursor: pointer;
  width: 100%;
  // height: 15rem;
  height: 15rem;
  // height: 100%; //have bug in Safari

  background-color: #181b1e;
  border-radius: 0.5rem;
  &:hover {
    background-color: #212529;

    & .coverFrame {
      // opacity: 0.4;
    }

    & .coverOpen {
      &::before {
        background-color: transparent;
      }
    }
  }
}

.divPaddingContainer {
  display: flex;
  align-items: flex-end;
  // width: 100%;
  height: 100%;
  // padding: 1rem  1rem  0.5rem 1.5rem;
  // new
  padding: 1rem 1rem 1rem 1.5rem;
  padding: 1rem 1rem 1rem 2rem;
}

.centerTitle {
  display: flex;
  flex-direction: column;
  height: 100%;
  // justify-content: space-between;
}

.coverFrame {
  width: 100%;
  height: 15rem;
  // width: 14.5rem;
  width: 100%;
  border-radius: 0.5rem 0 0 0.5rem;

  @media (min-width: 800px) {
    width: 100%;
  }
}

.coverOpen {
  position: relative;
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 0.5rem 0 0 0.5rem;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #0606067f;
  }
}

.textDiv {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.titleTest {
  // new
  display: flex;
  height: 100%;
  align-items: center;

  font-size: 1.8rem;
  font-weight: 300;
  color: #adb5bd;

  @media (min-width: 700px) {
    font-size: 1.6rem;
  }
}

.divCenterBlogger {
  display: flex;
  // align-items: center;
  align-items: flex-start;
  justify-content: space-between;
}

.bloggerName {
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: #ced7df;

  @media (min-width: 700px) {
    font-size: 1.2rem;
  }

  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    font-size: 1.2rem;
  }

  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    font-size: 1.2rem;
  }
}

.bloggerAvatar {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;

  @media (min-width: 700px) {
    height: 2rem;
    width: 2rem;
  }
}

.divResult {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  // padding-top: 1rem;
  // height: 6rem;
  height: 4rem;
}

.status {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  color: #adb5bd;
  width: 50%;
  // padding-right: 2rem;
  padding-right: 1rem;
  flex-grow: 1;

  @media (min-width: 700px) {
    font-size: 1.2rem;
  }

  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    font-size: 1.2rem;
  }

  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    font-size: 1.2rem;
  }
}

.statusIcon {
  height: 85%;
  opacity: 0.2;
  margin-right: -1rem;
}

.IconBigBackgroung {
  width: 40%;
  opacity: 0.3;
  margin-bottom: -1rem;
}

.status,
.status span {
  // color: #3bc9db;
  color: #f59f00;
  & span {
    font-weight: 900;
  }
}

.priceNumber {
  color: #f59f00;
  font-size: 1.8rem;
  font-weight: 500;
  margin-right: 0.5rem;
  // margin-bottom: 0.25rem;
}

.flexGrow {
  flex-grow: 1;
}

.policyLink {
  color: '#adb5bdaa' !important;
  font-size: '1.2rem' !important;
  margin-top: '1rem';

  & a,
  a:active {
    color: '#adb5bdaa';
    font-size: '1.2rem';
    margin-top: '1rem';
  }
}
