@import '../../../style/variables.scss';

input[type='radio'] {
  display: none;
}

.radioContainer {
  width: 100%;
  height: 100%;
  margin: 2px 0 2px 0;
  cursor: pointer;

  @media (min-width: 800px) {
    width: 50%;
  }
}
