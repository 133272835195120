.headerProfile {
  position: relative;
  margin-top: -0.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 6fr;

  justify-content: flex-start;
  align-items: flex-end;
  padding: 1rem 0 2rem 0;

  @media (min-width: 800px) {
    grid-template-columns: 5fr 6fr 1fr;
    column-gap: 2rem;
  }
}

.avatar {
  background-color: #adb5bd;
  width: 14.5rem;
  height: 14.5rem;
  margin-right: 2.5rem;

  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  flex-shrink: 0;
}

.box {
  display: flex;
  width: 100%;
  height: 14.5rem;
  flex-direction: column;
  justify-content: flex-end;
}
