@import '../../../style/variables.scss';

.textContainer,
.textContainerChecked,
.textContainerTrue,
.textContainerFalse {
  cursor: pointer;
  height: 100%;
  // min-height: 5rem;
  min-height: 4rem;
  width: 100%;
  padding: 1rem;
  // background-color: #1f2224;
  background-color: #181b1e;
  border: 1px solid #52585e57;
  border-radius: 0.5rem;

  // NEW
  display: flex;
  align-items: center;

  & span {
    font-size: 1.4rem;
    color: #ced4da;

    @media (min-width: 700px) {
      font-size: 1.3rem;
    }

    @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      font-size: 1.2rem;
    }

    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      font-size: 1.2rem;
    }
  }
}

.textContainerChecked {
  border: 1px solid #5e666e;
  background-color: #2125295a;
  & span {
    color: $colorAccentOrange;
  }
}

.textContainerTrue {
  border: 1px solid $colorAccentOrange;
  background-color: $colorAccentOrange;
  & span {
    color: #212529;
  }
}

.textContainerFalse {
  border: 1px solid rgba(227, 8, 44, 0.416);
  background-color: rgba(227, 8, 44, 0.416);
  & span {
    color: white;
  }
}
