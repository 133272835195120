.details {
  margin-top: 2rem;
  font-size: 1.4rem;
  color: #adb5bd;
}

.login {
  margin-top: 0.25rem;

  text-align: left;
  font-size: 1.8rem;
  font-weight: 900;
  color: #adb5bd;
  line-height: 1.1;
}
